import React, { useRef, useEffect, Fragment, useState } from "react";
import { Footer } from "../../components/Footer";
import { MainLayout } from "../../components/MainLayout";
import { get_user, is_admin } from "../../auth";
import styled from "styled-components";
import Select from "react-select";
import {
  getPaymentAsync,
  StatementState,
  slice,
  Settlement,
} from "./statementSlice";
import { DateTime } from "luxon";
import { RootState } from "../../app/store";
import { useDispatch, useSelector } from "react-redux";
import _ from "lodash";
import { Modal } from "../../components/Modal";
import { RequestPaymentModal } from "../../components/RequestPaymentModal";
import { useHistory, useLocation, Link } from "react-router-dom";

const Message = styled.div`
  width: 100%;
  text-align: center;
  color: red;
`;

const ContactEmail = styled.span`
  font-weight: bold;
`;

const Th = styled.th`
  border: none !important;
  &:last-child {
    text-align: right !important;
  }
`;

const Td = styled.td`
  border: none !important;
  &:last-child {
    text-align: right !important;
  }
`;

const BorderedDiv = styled.div`
  border: solid 2px black;
  border-radius: 45px;
  margin-top: 20px;
  padding: 1.2rem;
`;

const Remark = styled.div.attrs(() => ({ className: "has-text-left-desktop" }))`
  padding-top: 1em;
`;

const RemarkTerm = styled.span`
  text-decoration: underline;
`;

// const settlements = [
//   {date: '16/08/2020', amount: 350},
//   {date: '30/06/2020', amount: 500},
// ];

// const datesUpdated = ['10/06/2020', '01/07/2020'];

const remarks = [
  [
    "Revenue Share",
    " as defined in KaiOS Application Submission and Distribution Agreement",
  ],
  [
    "Outstanding Payment",
    " is the amount to include in next payment and settle according to KaiOS Application and Distribution Agreement",
  ],
  [
    "In Progress",
    " is the amount being processed internally before it is ready for settlement",
  ],
];




export const Statement: React.FC = () => {
  const formRef = useRef<HTMLFormElement | null>(null);
  const submitBtnRef = useRef<HTMLButtonElement | null>(null);
  const dispatch = useDispatch();
  const state = useSelector<RootState, StatementState>(
    (state: any) => state[slice.name]
  );

  const useModal = () => {
    const [isShown, setIsShown] = useState<boolean>(false);
    const toggle = () => setIsShown(!isShown);
    return {
      isShown,
      toggle,
    };
  };

  const { isShown, toggle } = useModal();

  const onCancel = () => toggle();

  useEffect(() => {
    submitBtnRef.current?.click();
  }, []);
  let balance = state.balances.find(
    (x) => x.date.getTime() === state.selectedDate?.getTime()
  );
  let d = (x: Date) => DateTime.fromJSDate(x).toFormat("dd/MM/yyyy");

  let settlementsb4: Settlement[] = _.chain(
    state.settlements.filter((x) =>
      state.selectedDate ? x.date <= state.selectedDate : false
    )
  )
    .groupBy((v) => d(v.date))
    .map(
      (v): Settlement => ({
        date: v[0].date,
        amount: _.sumBy(v, (x) => x.amount),
      })
    )
    .value();

  let total = settlementsb4.map((x) => x.amount).reduce((a, b) => a + b, 0);

  let lastPaymentReqInfo = state.lastPaymentReqInfo[0];

  const location = useLocation();
  const history = useHistory();

  const queryParams = new URLSearchParams(location.search);

  let msg1 = queryParams.get("msg1");
  let msg2 = queryParams.get("msg2");
  let msg3 = queryParams.get("msg3");

  return (
    <MainLayout>
      {balance ? (
        <Modal
          isShown={isShown}
          hide={toggle}
          headerText="Request Payment Form"
          modalContent={
            <RequestPaymentModal
              onCancel={onCancel}
              pubID={balance?.pubID}
              outstandingAmount={balance?.outstanding}
              contact_email={lastPaymentReqInfo?.contact_email}
              payment_method={lastPaymentReqInfo?.payment_method}
              full_or_company_name={lastPaymentReqInfo?.full_or_company_name}
              bank_name={lastPaymentReqInfo?.bank_name}
              bank_address={lastPaymentReqInfo?.bank_address}
              bank_acc_name={lastPaymentReqInfo?.bank_acc_name}
              swift_code={lastPaymentReqInfo?.swift_code}
              payoneer_acc={lastPaymentReqInfo?.payoneer_acc}
              paypal_acc={lastPaymentReqInfo?.paypal_acc}
              kaidsp_acc={lastPaymentReqInfo?.kaidsp_acc}
            />
          }
        />
      ) : (
        ""
      )}
      <div className="container is-fluid compact">
        {msg1 && msg2 && msg3 ? (
          <>
            <Message>{msg1}</Message>
            <Message>
              {msg2}
              <ContactEmail>
                <Link
                  to="/payment?msg1=Your%20request%20has%20been%20received%20and%20will%20be%20treated%20by%20our%20team.%20(Payments%20are%20processed%20on%202nd%20and%204th%20Friday%20each%20month.)&msg2=If%20you%20have%20any%20issue%2C%20please%20contact%20&msg3=developersupport%40kaiostech.com"
                  style={{ color: "red" }}
                  onClick={() => (window.location.href = `mailto:${msg3}`)}
                >
                  <b>{msg3}</b>
                </Link>
              </ContactEmail>
              &nbsp;.
            </Message>
          </>
        ) : (
          ""
        )}
        <div className="section">          
         
        <div style={{
          fontSize: '0.8rem',
          margin: '0.5rem 0px 2rem',
          left: '0px',
          right: '0px',
          backgroundColor: '#ffffcc',
          color: 'black',
          padding: '1rem',
          borderRadius: '0.3rem',
        }}>
        <div>
        <br />
         2024-12-13: We are currently updating our payment and revenue platform, there may be some delay in confirming the final amount or receiving the payment request. If you meet an issue, please contact us by email at{' '}
         <a href="mailto:developersupport@kaiostech.com">
           <b>developersupport@kaiostech.com</b>
         </a>.
       </div>
       
       
        </div>
        
          <div className="columns is-centered">
            <div className="column is-narrow">
              <BorderedDiv className="columns is-centered">
                <div className="column is-narrow">
                  <form
                    ref={formRef}
                    onSubmit={(e) => {
                      e.preventDefault();
                      queryParams.delete("msg1");
                      queryParams.delete("msg2");
                      queryParams.delete("msg3");
                      history.replace({
                        search: queryParams.toString(),
                      });
                      dispatch(getPaymentAsync(new FormData(e.currentTarget)));
                    }}
                  >
                    {is_admin() ? (
                      <Fragment>
                        <input
                          className="input"
                          type="text"
                          placeholder="Publisher ID/email"
                          name="publisher_id"
                          required
                        />

                        <button
                          ref={submitBtnRef}
                          className="button is-primary"
                          type="submit"
                        >
                          Submit
                        </button>
                        <br />
                        <br />
                      </Fragment>
                    ) : (
                      <Fragment>
                        <input
                          className="input"
                          type="hidden"
                          name="publisher_id"
                          value={get_user().PublisherId}
                        />
                        <button
                          ref={submitBtnRef}
                          className="button is-primary is-hidden"
                          type="submit"
                        >
                          Submit
                        </button>
                      </Fragment>
                    )}
                  </form>
                  <span>As of </span>
                  <div style={{ display: "inline-block" }}>
                    <Select
                      options={state.dates.map((x) => ({
                        value: x,
                        label: d(x),
                      }))}
                      value={
                        state.selectedDate
                          ? {
                              value: state.selectedDate,
                              label: d(state.selectedDate),
                            }
                          : null
                      }
                      onChange={(value) => {
                        if (value && "value" in value) {
                          dispatch(
                            slice.actions.select_date(value?.value.toString())
                          );
                        }
                      }}
                      classNamePrefix="select"
                      placeholder="Select date"
                      width="200px"
                    />
                  </div>
                </div>
                <div className="column is-narrow">
                  <table className="table">
                    <thead>
                      <tr>
                        <Th>
                          <span className="tag is-primary is-medium">
                            Revenue Statement
                          </span>
                        </Th>
                        <Th style={{ verticalAlign: "middle" }}>US$</Th>
                      </tr>
                      <tr>
                        <Th>Accumulated Revenue Share*</Th>
                        <Th>
                          {balance
                            ? (
                                balance.paid +
                                balance.outstanding +
                                balance.inprogress
                              ).toFixed(2)
                            : ""}
                        </Th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <Td>Paid</Td>
                        <Td>{balance?.paid.toFixed(2)}</Td>
                      </tr>
                      <tr>
                        <Td>Outstanding Payment**</Td>
                        <Td>{balance?.outstanding.toFixed(2)}</Td>
                      </tr>
                      <tr>
                        <Td>&nbsp;</Td>
                        <Td>
                          {balance !== undefined &&
                          balance.outstanding >= 500 &&
                          state.selectedDate?.toString() ===
                            state.dates[0].toString() ? (
                            <Fragment>
                              <button
                                onClick={toggle}
                                ref={submitBtnRef}
                                className="tag button"
                                style={{
                                  justifyContent: "center",
                                  color: "white",
                                  backgroundColor: "#1E90FF",
                                  borderRadius: "1rem",
                                }}
                                type="submit"
                              >
                                Request Payment
                              </button>
                              {lastPaymentReqInfo?.requested_amount &&
                              lastPaymentReqInfo?.created_at &&
                              !msg1 &&
                              !msg2 &&
                              !msg3 ? (
                                <>
                                  <div style={{ fontSize: "0.7rem" }}>
                                    (Last Request:{" "}
                                    <b>
                                      ${lastPaymentReqInfo?.requested_amount}
                                    </b>{" "}
                                    on{" "}
                                    <b>
                                      {DateTime.fromISO(
                                        lastPaymentReqInfo?.created_at
                                      ).toFormat("yyyy-MM-dd")}
                                    </b>
                                    )
                                  </div>
                                </>
                              ) : (
                                ""
                              )}
                              <br />
                              <br />
                            </Fragment>
                          ) : (
                            ""
                          )}
                        </Td>
                      </tr>
                      <tr>
                        <Td>In Progress***</Td>
                        <Td>{balance?.inprogress.toFixed(2)}</Td>
                      </tr>
                    </tbody>
                  </table>
                  <div style={{ overflowY: "scroll", maxHeight: 300 }}>
                    <table className="table" style={{ width: "100%" }}>
                      <thead>
                        <tr>
                          <Th>
                            <span className="tag is-torquoise is-medium">
                              Payment History
                            </span>
                          </Th>
                          <Th></Th>
                        </tr>
                        <tr>
                          <Th>Settlement date</Th>
                          <Th>US$</Th>
                        </tr>
                      </thead>
                      <tbody>
                        {settlementsb4.map((x) => (
                          <tr>
                            <Td>{d(x.date)}</Td>
                            <Td>{x.amount.toFixed(2)}</Td>
                          </tr>
                        ))}
                      </tbody>
                      <tfoot>
                        <tr>
                          <Td>Total: </Td>
                          <Td>{total ? total.toFixed(2) : ""}</Td>
                        </tr>
                      </tfoot>
                    </table>
                  </div>
                </div>
              </BorderedDiv>
            </div>
          </div>

          {remarks.map((r, k) => (
            <Remark key={k}>
              {"*".repeat(k + 1)}
              <RemarkTerm key={k}>{r[0]}</RemarkTerm>
              {r[1]}
            </Remark>
          ))}
        </div>
      </div>
      <Footer />
    </MainLayout>
  );
};
